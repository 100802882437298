import React from "react";

import { SEO } from "../components/seo";
import { Layout } from "../components/layout";
import { CenterSection } from "../components/center-section";

export default () => (
  <Layout>
    <SEO title="Impressum" />
    <CenterSection>
      <div className="my-16">
        <h1 className="text-2xl font-heading mb-6">IMPRESSUM</h1>
        <p>
          <strong>Fil der Protagonist GbR</strong>
          <br />
          Beethovenstr. 8<br />
          40233 Düsseldorf
          <br />
          <a
            href="mailto:filderprotagonist@gmail.com"
            className="text-blue-300 hover:underline"
          >
            filderprotagonist@gmail.com
          </a>
        </p>
      </div>
    </CenterSection>
  </Layout>
);
